import { Button, Form } from "react-bootstrap";
const Thanks = ({ newQuote }) => {
  return (
    <section id="thanks" className="min-vh-60 d-flex align-items-center bg-green-light-cu p-5">
      <div className="px-5 mx-5 w-100 d-flex flex-column  gap-sm-none gap-3">
        <h1 className="thanks-title">Gracias por confiar en nosotros</h1>
        <div className=" no-border rounded-3 bg-green-light-cu-2 w-100 p-5">
          <div><p className="thanks-text">Estamos trabajando para preparar una cotización personalizada que se ajuste a tus necesidades específicas. Pronto recibirás todos los detalles para que puedas tomar una decisión informada. Tu satisfacción es nuestra prioridad.</p></div>
          <Form onSubmit={newQuote} className="d-none d-sm-flex justify-content-end">
            <Button type="submit" variant="primary">Nueva Cotización</Button>
            </Form>
        </div>
        <Form onSubmit={newQuote} className="d-flex d-sm-none">
            <Button  type="submit" variant="primary">Nueva Cotización</Button>
            </Form>
      </div>
    </section>
  );
};

export default Thanks;